.container {
    max-width: 70vw;
    margin: auto;
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #cccccc;
  }
  
  .statusButton {
    margin: 5px;
    width: 40px;
    height: 25px;
  }
  
  .status {
    margin: 5px;
  }
  
  .statusColumn {
    display: flex;
    justify-content: center;
  }

  .softwareRequestListContainer{
    display: flex;
    justify-content: space-between;
  }
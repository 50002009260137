.promote-software-container {
    // background-color: #cccccc;
    max-width: 1000px;
    margin: auto;
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #cccccc;
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: auto;
    min-height: 120px;
}

.inner-container {
    display: flex;
    flex-wrap: wrap;
    & > * {
        margin-right: 20px;
    }
}

.textfield-inner {
    width: 300px;
}

.buttonContainer {
    margin-top: 20px;
}

.results-container {
    margin-top: 40px;
}

table {
    // border-spacing: 1em .5em;
    padding: 0 2em 1em 0;
    border: 1px solid #000000;
    border-radius: 4px;
    margin-top: 20px;
}

th {
    padding: 16px;
}
td {
    min-width: 100px;
    padding: 4px;
    margin: 4px;
    text-align: center;
    vertical-align: middle;
}

.checkbox-container {
    display: flex;
    justify-content: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, #root>div {
  height: 100%
}

.ms-DetailsHeader-cellName,
.ms-DetailsRow-cell{
  margin: auto;
  text-align: center;
}

.loginPageContainer{
  margin: auto;
  margin-top: 60px;
  padding: 15px;
  border: 1px solid #cccccc;
  max-width: 1000px;
  max-height: 500px;
  display: flex;
  justify-content: center;
}

.loginContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginHeading{
  margin: 50px 0;
}

.loginTermsConditionText{
  font-size: 12px;
  margin-top: 5px;
}
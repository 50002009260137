.app-header {
    border: 1px solid #d3d3d3;
    padding: 16px;
    display: flex;
    justify-content: space-between;
}

.app-heading {
    font-size: 30px;
    font-weight: 600;
}

.username {
    overflow: hidden;
    max-width: 30ch;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nav-bar-container {
    display: flex;
    height: 100%;
}

.app-container {
    flex: 1;
}
.container {
  max-width: 60vw;
  margin: auto;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #cccccc;
}

.statusButton {
  margin: 5px;
  width: 40px;
  height: 25px;
}

.status {
  margin: 5px;
}

.statusColumn {
  display: flex;
  justify-content: center;
}

.jobInProgress {
  color: blue;
}

.jobFailed {
  color: red;
}

.jobCompleted {
  color: green;
}

.jobDetailsTable,
.jobDetailsRow,
.jobDetailsNameColumn,
.jobDetailsColumn {
  border: 1px solid #d3d3d3;
  border-collapse: collapse;
  font-size: 12px;
  font-weight: 500;
  color: rgb(96, 94, 92);
  font-family: "Segoe UI";
  text-align: left;
}

.jobDetailsNameColumn {
  min-width: 225px;
}

.jobDetailsColumn {
  max-width: 750px;
  word-wrap: break-word;
  font-weight: 400;
}

.jobDetailsHeaderContainer {
  display: flex;
  justify-content: space-between;
}